define("itarp-auth-addon/services/ad-token-service", ["exports", "@ember/service", "@ember/object", "js-base64"], function (_exports, _service, _object, _jsBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let AdTokenService = (_class = class AdTokenService extends _service.default {
    constructor() {
      super(arguments);

      _defineProperty(this, "token", null);

      this.validateToken();
    }

    validateToken() {
      this.assureTokenExistence();
      this.assureTokenNotExpired();
    }

    assureTokenExistence() {
      let token = this.retreiveToken();

      if (token) {
        localStorage.setItem('itarp_token', token);
      } else {
        token = localStorage.getItem('itarp_token');

        if (!token) {
          this.redirectToLogin();
        }
      }

      this.token = token;
    }

    get tokenInformation() {
      const decodedString = (0, _jsBase.decode)(this.token.split('.')[1]);
      return JSON.parse(decodedString);
    }

    get userId() {
      if (this.token) return this.tokenInformation.sub;
    }

    get tenantId() {
      if (this.token) return this.tokenInformation.extension_tenant_id;
    }

    get userType() {
      if (this.token) return this.tokenInformation.extension_user_type;
    }

    get companyId() {
      if (this.token) return this.tokenInformation.extension_company_id;
    }

    get b2cTenantName() {
      if (this.token) return this.tokenInformation.iss.split('.')[0].split('//')[1];
    }

    get b2cClientId() {
      if (this.token) return this.tokenInformation.aud;
    }

    assureTokenNotExpired() {
      if (this.isTokenExpired()) {
        localStorage.removeItem('itarp_token');
        this.deleteCookie();
        return this.redirectToLogin();
      }
    }

    isTokenExpired() {
      const currentTimeStamp = Math.floor(Date.now() / 1000);
      const tokenTimeStamp = this.tokenInformation.exp;
      return currentTimeStamp > tokenTimeStamp;
    }

    redirectToLogin(isLogout) {
      window.location = this.buildB2cUri(isLogout);
    }

    retrieveTokenFromHash() {
      const hash = window.location.hash.substr(1);
      const parts = hash.split('&').reduce(function (res, item) {
        const parts = item.split('=');
        res[parts[0]] = parts[1];
        return res;
      }, {});
      return parts['id_token'] ? parts['id_token'] : null;
    }

    retrieveTokenFromQuery() {
      const query = window.location.search.substr(1);
      const parts = query.split('?').reduce(function (result, item) {
        item.split('&').reduce(function (res, item) {
          const keyIndex = item.indexOf('=');
          const parts = [item.slice(0, keyIndex), item.slice(keyIndex + 1)];
          res[parts[0]] = parts[1];
          return res;
        }, result);
        return result;
      }, {});
      return parts['id_token'] ? parts['id_token'] : null;
    }

    retreiveTokenFromCookie() {
      const {
        COOKIE_NAME: cookieName
      } = this.getEnv();
      const cookies = document.cookie.split(';').map(cookie => cookie.trim());
      const token = cookies.find(cookie => cookie.startsWith(cookieName));
      return token ? token.split('=')[1] : null;
    }

    retreiveToken() {
      return this.retrieveTokenFromHash() || this.retrieveTokenFromQuery() || this.retreiveTokenFromCookie();
    }

    deleteCookie() {
      const {
        COOKIE_DOMAIN,
        COOKIE_NAME
      } = this.getEnv();
      document.cookie = `${COOKIE_NAME}=; domain=${COOKIE_DOMAIN}; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    }

    logOut() {
      const isLogout = true;
      localStorage.removeItem('itarp_token');
      this.deleteCookie();
      this.redirectToLogin(isLogout);
    }

    buildSwitchAccountUrl(userType) {
      const returnUrl = {
        expert: `${window.location.origin}/expert/`,
        tod_client: `${window.location.origin}/account/settings/`
      };
      return `https://${this.b2cTenantName}.b2clogin.com/${this.b2cTenantName}.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_AUTHORIZED_AS&client_id=${this.b2cClientId}&nonce=defaultNonce&redirect_uri=${returnUrl[userType]}&scope=openid&response_type=id_token&prompt=login&user_role=${userType}&client_assertion_type=urn%3Aietf%3Aparams%3Aoauth%3Aclient-assertion-type%3Ajwt-bearer&&client_assertion=${this.token}`;
    }

    buildB2cUri(isLogout) {
      const {
        landingPage
      } = this.getEnv();
      const currentURL = window.location.href;
      const encodedURL = btoa(currentURL).replaceAll('/', '-');
      const reffererQueryParam = !isLogout ? `&referrer=${encodedURL}` : '';
      return `${landingPage}?signout=${isLogout}${reffererQueryParam}`;
    }

    getEnv() {
      throw 'Method getEnv() is considered abstract and must be implemented in sub class';
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "validateToken", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "validateToken"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "logOut", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "logOut"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "buildSwitchAccountUrl", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "buildSwitchAccountUrl"), _class.prototype)), _class);
  _exports.default = AdTokenService;
});