define("itarp-opportunity-ui/components/forms/favorite", ["exports", "@glimmer/component", "@ember/object", "@ember/service"], function (_exports, _component, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <a
    class="card-block--job-header__bookmark
      {{if this.isUserFavorite "is-active"}}"
    {{on "click" this.onChangeFavorite}}
  > </a>
  */
  {
    "id": "6Bs/XZOC",
    "block": "[[[11,3],[16,0,[29,[\"card-block--job-header__bookmark\\n    \",[52,[30,0,[\"isUserFavorite\"]],\"is-active\"]]]],[4,[38,1],[\"click\",[30,0,[\"onChangeFavorite\"]]],null],[12],[1,\" \"],[13]],[],false,[\"if\",\"on\"]]",
    "moduleName": "itarp-opportunity-ui/components/forms/favorite.hbs",
    "isStrictMode": false
  });

  let FormsFavoriteComponent = (_class = class FormsFavoriteComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "toast", _descriptor2, this);

      const {
        opportunity
      } = this.args;
    }

    get isUserFavorite() {
      const {
        opportunity,
        userFavorites
      } = this.args;
      return userFavorites.includes(opportunity.id);
    }

    onChangeFavorite() {
      if (this.isUserFavorite) {
        this.deleteFavorite();
      } else {
        this.saveFavorite();
      }
    }

    saveFavorite() {
      const {
        opportunity,
        userFavorites,
        updateUserFavorites
      } = this.args;

      const success = () => {
        updateUserFavorites([...userFavorites, opportunity.id]);
        this.toast.show('Opportunity marked as favorite');
      };

      const failure = error => {
        if (error) {
          this.toast.show(error);
        }
      };

      const adapter = this.store.adapterFor('user-favorite');
      const endpoint = `${adapter.namespace}/favorites`;
      const method = 'POST';
      const headers = adapter.headers;
      fetch(endpoint, {
        method,
        headers,
        body: JSON.stringify({
          ids: [opportunity.id],
          resource_type: 'opportunity'
        })
      }).then(async response => await response.json()).then(success, failure);
    }

    deleteFavorite() {
      const {
        opportunity,
        userFavorites,
        updateUserFavorites
      } = this.args;

      const success = () => {
        updateUserFavorites(userFavorites.filter(id => id !== opportunity.id));
        this.toast.show('Opportunity removed from favorites');
      };

      const failure = error => {
        if (error) {
          this.toast.show(error);
        }
      };

      const adapter = this.store.adapterFor('user-favorite');
      const endpoint = `${adapter.namespace}/favorites`;
      const method = 'DELETE';
      const headers = adapter.headers;
      fetch(endpoint, {
        method,
        headers,
        body: JSON.stringify({
          ids: [opportunity.id],
          resource_type: 'opportunity'
        })
      }).then(async response => await response.json()).then(success, failure);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "toast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onChangeFavorite", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeFavorite"), _class.prototype)), _class);
  _exports.default = FormsFavoriteComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FormsFavoriteComponent);
});