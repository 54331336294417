define("itarp-opportunity-designer/routes/opportunity/index", ["exports", "@ember/routing/route", "@ember/service", "rsvp", "itarp-opportunity-designer/config/environment"], function (_exports, _route, _service, _rsvp, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class OpportunityIndexRoute extends _route.default {
    async model() {
      return this.modelFor('opportunity');
    }

    beforeModel(transition) {
      const opportunity = this.modelFor('opportunity');

      if (!opportunity.isNew) {
        this.transitionTo('opportunity.job-details');
      }
    }

    setupController(controller, model) {
      super.setupController(...arguments);
      controller.set('project', model);
    }

  }

  _exports.default = OpportunityIndexRoute;
});